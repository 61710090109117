<template>
  <app-event
    event-id="fizer-mission-heart"
    auth="login-only"
    :mapping="mapping"
  >
    <template v-slot:logo>
      <v-img :src="require('./assets/Pfizer.Logo.Black.png')" class="ma-4" />
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";

export default {
  name: "PfizerSuperheroesPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        quest: Quest,
      },
    };
  },
};
</script>
